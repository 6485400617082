import { createI18n } from 'vue-i18n';
import en from '~/locales/en.json';
import type { App } from 'vue';

export function setupI18n(app: App<Element>) {
	const i18n = createI18n({
		legacy: false,
		locale: 'en',
		messages: { en },
		datetimeFormats: {
			en: {
				short: {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				},
				time: {
					hour: 'numeric',
					minute: 'numeric',
				},
				timeShort: {
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				},
				timeShortWithYear: {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				},
				long: {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					weekday: 'short',
					hour: 'numeric',
					minute: 'numeric',
				},
			},
		},
	});

	app.use(i18n);
}
