import { Dictionary } from '@/utils/dictionary';

export enum UserType {
	Other = 1,
	Guest,
	Administrator,
	Confederation,
	Federation,
	Player,
	Referee,
	VolleyClub,
	VolleyTournamentManager,
	BeachTournamentManager,
	Photographer,
	PressOfficer,
	MediaPerson,
	MedicalPerson,
	ZonalDevProject,
	MedicalAdministrator,
	Supervisor,
	ConfederationBeachManager,
	Coach,
	Official,
	PressAdministrator,
	PressTranslator,
	PressWriter,
	PlayerAgent,
	CourseOrganizer,
	FederationSnowManager,
}

export const userTypes = new Dictionary(
	[
		{ no: UserType.Other, code: 'Other', label: 'Other' },
		{ no: UserType.Guest, code: 'Guest', label: 'Guest' },
		{ no: UserType.Administrator, code: 'Administrator', label: 'Administrator' },
		{ no: UserType.Confederation, code: 'Confederation', label: 'Confederation' },
		{ no: UserType.Federation, code: 'Federation', label: 'Federation' },
		{ no: UserType.Player, code: 'Player', label: 'Player' },
		{ no: UserType.Referee, code: 'Referee', label: 'Referee' },
		{ no: UserType.VolleyClub, code: 'VolleyClub', label: 'Volley Club' },
		{ no: UserType.VolleyTournamentManager, code: 'VolleyTournamentManager', label: 'Volley Tournament Manager' },
		{ no: UserType.BeachTournamentManager, code: 'BeachTournamentManager', label: 'Beach Tournament Manager' },
		{ no: UserType.Photographer, code: 'Photographer', label: 'Photographer' },
		{ no: UserType.PressOfficer, code: 'PressOfficer', label: 'Press Officer' },
		{ no: UserType.MediaPerson, code: 'MediaPerson', label: 'Media Person' },
		{ no: UserType.MedicalPerson, code: 'MedicalPerson', label: 'Medical Person' },
		{ no: UserType.ZonalDevProject, code: 'ZonalDevProject', label: 'Zonal Development Project' },
		{ no: UserType.MedicalAdministrator, code: 'MedicalAdministrator', label: 'Medical Administrator' },
		{ no: UserType.Supervisor, code: 'Supervisor', label: 'Supervisor' },
		{ no: UserType.ConfederationBeachManager, code: 'ConfederationBeachManager', label: 'Confederation Beach Manager' },
		{ no: UserType.Coach, code: 'Coach', label: 'Coach' },
		{ no: UserType.Official, code: 'Official', label: 'Official' },
		{ no: UserType.PressAdministrator, code: 'PressAdministrator', label: 'Press Administrator' },
		{ no: UserType.PressTranslator, code: 'PressTranslator', label: 'Press Translator' },
		{ no: UserType.PressWriter, code: 'PressWriter', label: 'Press Writer' },
		{ no: UserType.PlayerAgent, code: 'PlayerAgent', label: 'Player Agent' },
		{ no: UserType.CourseOrganizer, code: 'CourseOrganizer', label: 'Course Organizer' },
		{ no: UserType.FederationSnowManager, code: 'FederationSnowManager', label: 'Federation Snow Manager' },
	],
	'no'
);
