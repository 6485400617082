<template>
	<span class="tabular-nums tracking-tighter">{{ formatedTime }}</span>
</template>

<script name="DateTime" lang="ts" setup>
	import { format, parseISO } from 'date-fns';

	const props = defineProps({
		time: {
			type: String,
			required: false,
			default: null,
		},

		format: {
			type: String,
			required: false,
			default: 'dd MMM yyyy',
		},
	});

	const formatedTime = computed(() => {
		if (!props.time) {
			return '';
		}

		const parsedDate = parseISO(props.time);

		return format(parsedDate, props.format);
	});
</script>
