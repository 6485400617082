import { useMutation } from '@tanstack/vue-query';
import { http } from '~/infrastructure/http';

export function useLogout() {
	return useMutation({
		mutationFn: () => {
			return http.delete('/auth/logout');
		},
	});
}
