import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export function setupSentry(app: App<Element>): void {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_APP_SENTRY_DSN,
		environment: import.meta.env.MODE,
		release: app.config.globalProperties.sentryRelease,
		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			'TypeError: Load failed',
			'TypeError: Failed to fetch',
			'TypeError: NetworkError when attempting to fetch resource.',
		],
	});
}
