import dayjs from 'dayjs';

import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import isBetween from 'dayjs/esm/plugin/isBetween';
import utc from 'dayjs/esm/plugin/utc';

export function setupDaysJs(): void {
	dayjs.extend(isBetween);
	dayjs.extend(advancedFormat);
	dayjs.extend(utc);
}
