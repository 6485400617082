<script lang="ts" setup>
	import { useNotification } from '@/app/common/composables/use-notification';

	const { notifications } = useNotification();
</script>

<template>
	<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<TransitionGroup
				enter-active-class="transition duration-300 ease-out transform"
				enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<component
					:is="notification.component"
					v-for="notification in notifications"
					v-bind="notification.props"
					:key="notification.id"
					:notification="notification"
				></component>
			</TransitionGroup>
		</div>
	</div>
</template>
