import { authStatusQueryDefinition } from '~/app/auth/queries/use-auth-check';
import { hooks } from '~/infrastructure/hook';
import type { QueryClient } from '@tanstack/vue-query';

export async function init(queryClient: QueryClient) {
	const { status } = await queryClient.fetchQuery(authStatusQueryDefinition);

	if (status === 'authenticated') {
		await hooks.callHook('auth::login');
	}
}
