import NProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';
import { queryKeyFactory } from '~/app/auth/queries/query-key-factory';
import { queryClient } from '~/infrastructure/query-client';
import type { RouteRecordRaw, RouteLocationNormalized } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

router.afterEach(() => {
	NProgress.done();
});

router.beforeEach((to: RouteLocationNormalized) => {
	NProgress.start();

	const auth = queryClient.getQueryData<{ status: 'authenticated' | 'unauthenticated' }>(
		queryKeyFactory.checkAuthentication()
	);

	const hasPublicMiddleware = to.matched.every((r) => r.meta.middleware?.includes('public'));
	const hasGuestMiddleware = to.matched.some((r) => r.meta.middleware?.includes('guest'));
	const isLoggedIn = auth?.status === 'authenticated';

	if (hasPublicMiddleware) {
		return true;
	}

	if (hasGuestMiddleware && isLoggedIn) {
		return '/';
	}

	if (!hasGuestMiddleware && !isLoggedIn) {
		if (to.path !== '/') {
			return `/login?redirect=${encodeURI(to.path)}`;
		}

		return '/login';
	}

	return true;
});

export default router;
