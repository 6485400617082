import router from '~/infrastructure/router';
import type { RouteRecordRaw } from 'vue-router';

export function registerRoutes(routes: RouteRecordRaw[]) {
	for (const route of routes) {
		router.addRoute(route);
	}
}

export async function registerModule(name: string): Promise<void> {
	try {
		const { register } = await import(`../app/${name}/setup.ts`);

		register();
	} catch (e) {
		console.error(`Failed to import ${name} module`);
		console.error(e);
	}
}
