export class Dictionary<T extends Record<string, unknown>> {
	private $items: Map<unknown, T> = new Map();
	private $indexedByItems: Record<string, Map<unknown, T>> = {};
	private $defaultIndex: string;

	constructor(items: T[], defaultIndex: string) {
		this.$defaultIndex = defaultIndex;

		this.append(items);
	}

	keys() {
		return this.$items.keys();
	}

	values() {
		return this.$items.values();
	}

	entries() {
		return this.$items.entries();
	}

	get(key: unknown): T | undefined {
		return this.$items.get(key);
	}

	append(items: T[]) {
		for (const item of items) {
			this.$items.set(item[this.$defaultIndex], item);
		}
	}

	getByIndex(index: string, key: unknown): T | undefined {
		return this.$indexedByItems[index].get(key);
	}

	// TODO: Handle appending
	indexBy(index: string): void {
		const newIndex = new Map<unknown, T>();

		for (const item of Object.values(this.$items)) {
			newIndex.set(item[index], item);
		}

		this.$indexedByItems[index] = newIndex;
	}
}
