<script lang="ts" setup>
	import { MegaphoneIcon, XMarkIcon } from '@heroicons/vue/24/outline';
	import { parse } from '@lukeed/ms';
	import { useRegisterSW } from 'virtual:pwa-register/vue';
	import { useNotification } from '~/app/common/composables/use-notification';
	import UpdateFailedNotification from '~/app/core/components/notifications/update-failed-notification.vue';

	const { needRefresh, updateServiceWorker } = useRegisterSW({
		immediate: true,
		onRegisteredSW(url, registration) {
			if (!registration) {
				return;
			}

			setInterval(async () => {
				if (!(!registration.installing && navigator)) {
					return;
				}

				if ('connection' in navigator && !navigator.onLine) {
					return;
				}

				try {
					const response = await fetch(url, {
						method: 'HEAD',
						cache: 'no-store',
						headers: {
							'Cache-Control': 'no-cache',
						},
					});

					if (response.ok) {
						await registration.update();
					}
				} catch (e) {
					//
				}
			}, parse('1m')!);
		},
	});

	const { add } = useNotification();

	async function update() {
		try {
			await updateServiceWorker();
		} catch (e) {
			add(UpdateFailedNotification);

			setTimeout(async () => {
				await update();
			}, parse('10s')!);
		} finally {
			needRefresh.value = false;
		}
	}

	function close() {
		needRefresh.value = false;
	}
</script>

<template>
	<div v-if="needRefresh">
		<div class="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
			<div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
				<div class="rounded-lg bg-blue-500 p-2 shadow-lg sm:p-3">
					<div class="flex flex-wrap items-center justify-between">
						<div class="flex w-0 flex-1 items-center">
							<span class="flex rounded-lg bg-blue-800 p-2">
								<MegaphoneIcon class="h-6 w-6 text-white" />
							</span>

							<p class="ml-3 truncate font-medium text-white">
								<span> An update of this application is available! </span>
							</p>
						</div>

						<div class="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
							<button
								class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-blue-600 shadow-sm hover:bg-blue-50"
								@click="update"
							>
								Update
							</button>
						</div>

						<div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
							<button
								type="button"
								class="-mr-1 flex rounded-md p-2 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white"
								@click="close"
							>
								<span class="sr-only">Dismiss</span>
								<XMarkIcon class="h-6 w-6 text-white" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
