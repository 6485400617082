<script lang="ts" setup>
	import ErrorNotification from '~/app/common/components/error-notification.vue';
	import { useNotification } from '~/app/common/composables/use-notification';

	const props = defineProps({
		notification: {
			type: Object,
			required: true,
		},
	});

	const { remove } = useNotification();
	function close() {
		remove(props.notification.id);
	}
</script>

<template>
	<ErrorNotification @close="close">
		<p class="text-sm font-medium text-gray-900">Unable to contact the server</p>
		<p class="mt-1 text-sm text-gray-500">
			We could not connect to the server due to technical issue. Please, try again.
		</p>
		<p class="mt-1 text-sm text-gray-500">
			If the issue keeps happening,
			<a class="font-medium underline" href="mailto:vis.support@fivb.com">contact the support</a>.
		</p>
	</ErrorNotification>
</template>
