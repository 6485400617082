import type { Component } from 'vue';

export interface Notification {
	id: string;
	component: Component;
	props?: Record<string, unknown>;
}

const notifications = ref<Notification[]>([]);

function add(
	component: Component,
	options: { props?: Record<string, unknown>; unique?: boolean } = { props: {}, unique: true }
) {
	if (
		options.unique &&
		notifications.value.some((notification) => notification.component.__name === component.__name)
	) {
		return;
	}

	notifications.value.push({ component: shallowRef(component), id: Date.now().toString(), props: options.props });
}

function remove(id: string) {
	notifications.value = notifications.value.filter((notification) => notification.id !== id);
}

export function useNotification() {
	return { notifications, add, remove };
}
