<script lang="ts" setup>
	import { convertCode } from '~/utils/convertCode';

	const props = withDefaults(
		defineProps<{
			code: string;
			format?: 'fivb' | 'iso2';
		}>(),
		{
			format: 'fivb',
		}
	);

	const specialISOCodeMap = {
		eng: 'gb-eng',
		sco: 'gb-sct',
		wal: 'gb-wls',
		nir: 'gb-nir',
		sab: 'bq',
		eux: 'bq',
	};

	const unknownFlagSet = ['cs', 'su', 'yu'];

	const url = computed(() => {
		if (props.code.toLowerCase() === 'fiv') {
			return '/flags/fiv.png';
		}

		let computedCode = props.code.toLocaleLowerCase();

		if (props.format === 'fivb') {
			computedCode = convertFivbCodeToIso(props.code);
		}

		if (computedCode && unknownFlagSet.includes(computedCode)) {
			return '/flags/unknown.png';
		}

		if (computedCode) {
			return `/flags/${computedCode}.png`;
		}

		return '/flags/unknown.png';
	});

	function convertFivbCodeToIso(code: string) {
		if (Object.keys(specialISOCodeMap).includes(code.toLowerCase())) {
			return specialISOCodeMap[code.toLowerCase()];
		}

		return convertCode('fivb', 'iso2', code)?.toLowerCase();
	}
</script>

<template>
	<img :src="url" loading="lazy" width="24" height="18" alt="flag" />
</template>
