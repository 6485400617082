import { queryOptions } from '@tanstack/vue-query';
import { queryKeyFactory } from '~/app/auth/queries/query-key-factory';
import { http } from '~/infrastructure/http';

export function fetchAuthCheck() {
	return http.get<{ status: 'unauthenticated' | 'authenticated'; impersonating: boolean }>('/auth/check');
}

export const authStatusQueryDefinition = queryOptions({
	queryKey: queryKeyFactory.checkAuthentication(),
	queryFn: fetchAuthCheck,
	staleTime: Infinity,
	gcTime: Infinity,
});
