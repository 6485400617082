import { useMutation } from '@tanstack/vue-query';
import { http } from '~/infrastructure/http';

export function useStopImpersonating() {
	return useMutation({
		mutationFn() {
			return http.delete('/auth/impersonate');
		},
	});
}
