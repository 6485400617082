export enum WSVersion {
	VIS2009,
	VISV2,
	VISSharp,
}

interface HttpClientOptions {
	headers?: HeadersInit;
	version?: WSVersion;
	isXml?: boolean;
}

const defaultHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/xml',
};

export class HttpError extends Error {
	code: number;
	response: Record<string, unknown> | string;

	constructor(code: number, message: string, body: Record<string, string> | string) {
		super(message);

		this.code = code;
		this.response = body;
	}
}

class HttpClient {
	private environment;

	constructor(
		private baseUrl: string,
		environment: 'production' | 'uat' | 'development'
	) {
		this.environment = localStorage.getItem('httpClient:environment') || environment;
	}

	public compute(endpoint: string) {
		const url = new URL(this.baseUrl + endpoint);

		url.searchParams.append('env', this.environment);

		return url.toString();
	}

	public open(relativeUrl: string, target = '_blank') {
		const url = `${this.baseUrl}${relativeUrl}?env=${this.environment}`;

		window.open(url, target);
	}

	public send(request: string, options: HttpClientOptions = {}) {
		if (!options.version) {
			options.version = WSVersion.VIS2009;
		}

		const proxyEndpoint =
			options.version === WSVersion.VIS2009 ? '/' : options.version === WSVersion.VISV2 ? '/v2' : '/v3';
		const url = `${this.baseUrl}${proxyEndpoint}`;

		return fetch(url, {
			headers: {
				...defaultHeaders,
				...(options.headers && options.headers),
				...(options.isXml && { Accept: 'application/xml' }),
				'X-FIVB-Env': this.environment,
			},
			credentials: 'include',
			method: 'post',
			body: request,
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((body) => {
					throw new HttpError(response.status, response.statusText, body);
				});
			}

			if (response.headers.get('content-type')?.includes('application/json')) {
				return response.json();
			}

			return response.text();
		});
	}

	public sendRaw(options: RequestInit & HttpClientOptions) {
		if (!options.version) {
			options.version = WSVersion.VIS2009;
		}

		const proxyEndpoint = options.version === WSVersion.VIS2009 ? '/' : '/v2';
		const url = `${this.baseUrl}${proxyEndpoint}`;

		return fetch(url, {
			credentials: 'include',
			headers: {
				'X-FIVB-Env': this.environment,
			},
			...options,
		}).then((response) => {
			if (!response.ok) {
				return response.text().then((body) => {
					throw new HttpError(response.status, response.statusText, body);
				});
			}

			if (response.headers.get('content-type')?.includes('application/json')) {
				return response.json();
			}

			return response.text();
		});
	}

	public get(url: string, options: RequestInit = {}) {
		return this.request(url, { ...options, method: 'get' });
	}

	public post(url: string, body?: Record<any, any> | string, options: RequestInit = {}) {
		body = typeof body === 'object' ? JSON.stringify(body) : body;
		return this.request(url, { ...options, body: body, method: 'post' });
	}

	public delete(url: string, options: RequestInit = {}) {
		return this.request(url, { ...options, method: 'delete' });
	}

	public request(url: string, options: RequestInit = {}) {
		return fetch(`${this.baseUrl}${url}`, {
			...options,
			headers: {
				...defaultHeaders,
				...(options.body && { 'Content-Type': 'application/json' }),
				...(options.headers && options.headers),
				'X-FIVB-Env': this.environment,
			},
			credentials: 'include',
		}).then((response) => {
			if (!response.ok) {
				return response.json().then((body) => {
					throw new HttpError(response.status, response.statusText, body);
				});
			}

			if (response.headers.get('content-type')?.includes('application/json')) {
				return response.json();
			}

			return response.text();
		});
	}

	public getEnvironment() {
		return this.environment;
	}

	public changeEnvironment(environment: 'production' | 'uat' | 'development') {
		localStorage.setItem('httpClient:environment', environment);
		this.environment = environment;
	}
}

export default new HttpClient(import.meta.env.VITE_APP_API_URL, import.meta.env.VITE_APP_DEFAULT_ENV || 'production');
