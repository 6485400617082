export function setupKonami(): void {
	let cursor = 0;
	const KONAMI_CODE = [
		'ArrowUp',
		'ArrowUp',
		'ArrowDown',
		'ArrowDown',
		'ArrowLeft',
		'ArrowRight',
		'ArrowLeft',
		'ArrowRight',
		'b',
		'a',
	];

	document.addEventListener('keydown', (e) => {
		cursor = e.key == KONAMI_CODE[cursor] ? cursor + 1 : 0;
		if (cursor == KONAMI_CODE.length) {
			const a = '-webkit-',
				b = 'transform:rotate(1turn);',
				c = 'transition:4s;';

			document.head.querySelector('style[data-egg]')?.remove();
			requestAnimationFrame(
				() => (document.head.innerHTML += `<style data-egg>body{${a}${b}${a}${c}${b}${c}}</style>`)
			);
			cursor = 0;
		}
	});
}
